import React from 'react';
import {Avatar, Box, Card, CardContent, Container, IconButton, Typography,} from "@mui/material";
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import Grid from "@mui/material/Grid2";
import gpLogo from "../../icons/Cherry-GP-nobg.png";
import dentistLogo from "../../icons/Cherry-Dentist-nobg.png";
import cardiologistLogo from "../../icons/Cherry-Cardiologist-nobg.png";

export default function () {
    const logoSize = 150;

    return (
        <Container id="disciplines" sx={{py: 8}} style={{backgroundColor: palette.background4, borderRadius: 20}}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 400, fontSize: 45}}
            >
                <strong style={{color: palette.accent3}}>Cherry</strong>, an AI that works as hard as you do.
            </Typography>
            <Typography
                variant="h6"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.primary, fontSize: 25}}
            >
                Our AI Models are declined in multiple variants to better serve the specific needs of different medical
                disciplines.
            </Typography>
            <Grid container spacing={4}>
                <Grid size={{xs: 12, sm: 4, md: 4}}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }}
                    >
                        <CardContent sx={{flexGrow: 1, textAlign: 'center'}}>
                            <Box
                            >
                                <img
                                    src={dentistLogo}
                                    style={{width: logoSize, height: logoSize}}
                                />
                            </Box>
                            <Typography gutterBottom variant="h5">
                                Cherry AI Dentist v1.2
                            </Typography>
                            <Typography>
                                Effortlessly manage profiles, capture notes, and receive instant diagnostic
                                recommendations. Cherry AI Dentist has access to domain knowledge specific to
                                dentistry to enhance patient care in the dental chair.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, sm: 4, md: 4}}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            }
                        }}
                    >
                        <CardContent sx={{flexGrow: 1, textAlign: 'center'}}>
                            <Box
                            >
                                <img
                                    src={cardiologistLogo}
                                    style={{width: logoSize, height: logoSize}}
                                />
                            </Box>
                            <Typography gutterBottom variant="h5">
                                Cherry AI Cardiologist v1.2
                            </Typography>
                            <Typography>
                                Streamline patient management, record key data, and receive timely diagnostic insights.
                                Cherry AI Cardiologist is equipped with specialized cardiovascular knowledge to support
                                complex decision-making and enhance patient care in the clinic.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, sm: 4, md: 4}}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            }
                        }}
                    >
                        <CardContent sx={{flexGrow: 1, textAlign: 'center'}}>
                            <Box
                            >
                                <img
                                    src={gpLogo}
                                    style={{width: logoSize, height: logoSize}}
                                />
                            </Box>
                            <Typography gutterBottom variant="h5">
                                Cherry AI GP v1.2
                            </Typography>
                            <Typography>
                                Specifically designed for GPs, Cherry AI GP has increased capability in providing
                                referral suggestions and has a wider range of knowledge to help with the diverse
                                situations encountered by GPs.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 12}}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            }
                        }}
                    >
                        <CardContent     sx={{
                            flexGrow: 1,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Typography gutterBottom variant="h5">
                                More coming soon...
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}